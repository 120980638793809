<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="card card-custom gutter-b example example-compact"
          v-if="filter"
        >
          <div class="card-header">
            <div class="row align-items-center pt-5 pb-5">
              <div class="col-lg-10 col-xl-8">
                <div class="row align-items-center">
                  <div class="col-md-2 my-2 my-md-0">
                    <div class="input-icon">
                      <input
                        type="text"
                        v-model="reg_number__icontains"
                        v-debounce:400ms="myFn"
                        class="form-control"
                        placeholder="Raqami"
                        id="kt_datatable_search_query"
                      />
                      <span>
                        <i class="flaticon2-search-1 text-muted"></i>
                      </span>
                    </div>
                  </div>
                  <div class="col-md-2 my-2 my-md-0">
                    <div class="input-icon">
                      <input
                        type="text"
                        v-model="contragent__full_name__icontains"
                        v-debounce:400ms="myFn"
                        class="form-control"
                        placeholder="Kontragent"
                        id="kt_datatable_search_query"
                      />
                      <span>
                        <i class="flaticon2-search-1 text-muted"></i>
                      </span>
                    </div>
                  </div>
                  <div class="col-md-3 my-2 my-md-0">
                    <div class="input-icon">
                      <input
                        type="text"
                        v-model="contragent_contract__reg_number__icontains"
                        v-debounce:400ms="myFn"
                        class="form-control"
                        placeholder="Kontragent shartnomasi"
                        id="kt_datatable_search_query"
                      />
                      <span>
                        <i class="flaticon2-search-1 text-muted"></i>
                      </span>
                    </div>
                  </div>
                  <div class="col-md-2 my-2 my-md-0">
                    <div class="d-flex align-items-center">
                      <!-- <label class="mr-3 mb-0 d-none d-md-block">Type:</label> -->
                      <el-date-picker
                        v-model="from_date"
                        type="date"
                        format="yyyy-MM-dd"
                        @change="operDate(from_date,'from_date')"
                        placeholder="Sanadan"
                      >
                      </el-date-picker>
                    </div>
                  </div>
                  <div class="col-md-2 my-2 my-md-0">
                    <div class="d-flex align-items-center">
                      <!-- <label class="mr-3 mb-0 d-none d-md-block">Type:</label> -->
                      <el-date-picker
                        v-model="to_date"
                        type="date"
                        format="yyyy-MM-dd"
                        @change="operDate(to_date, 'to_date')"
                        placeholder="Sanagacha"
                      >
                      </el-date-picker>
                    </div>
                  </div>
                  <div class="col-md-2 my-2 my-md-0">
                    <div class="input-icon">
                      <input
                        type="text"
                        v-model="
                          placement_expenditure__category__name__icontains
                        "
                        v-debounce:400ms="myFn"
                        class="form-control"
                        placeholder="Xarajat"
                        id="kt_datatable_search_query"
                      />
                      <span>
                        <i class="flaticon2-search-1 text-muted"></i>
                      </span>
                    </div>
                  </div>

                  <div class="col-md-3 my-2 my-md-0">
                    <div class="input-icon">
                      <input
                        type="text"
                        v-model="storedproductservice__total"
                        v-debounce:400ms="myFn"
                        class="form-control"
                        placeholder="Summa"
                        id="kt_datatable_search_query"
                      />
                      <span>
                        <i class="flaticon2-search-1 text-muted"></i>
                      </span>
                    </div>
                  </div>

                  <div class="col-md-2 my-2 my-md-0">
                    <div class="input-icon">
                      <input
                        type="text"
                        v-model="storedproductservice__nds_summa"
                        v-debounce:400ms="myFn"
                        class="form-control"
                        placeholder="NDS"
                        id="kt_datatable_search_query"
                      />
                      <span>
                        <i class="flaticon2-search-1 text-muted"></i>
                      </span>
                    </div>
                  </div>

                  <div class="col-md-2 my-2 my-md-0">
                    <div class="input-icon">
                      <input
                        type="text"
                        v-model="storedproductservice__total_withnds_summa"
                        v-debounce:400ms="myFn"
                        class="form-control"
                        placeholder="Summa (NDS)"
                        id="kt_datatable_search_query"
                      />
                      <span>
                        <i class="flaticon2-search-1 text-muted"></i>
                      </span>
                    </div>
                  </div>

                  <div class="col-md-2 my-2 my-md-0">
                    <div class="d-flex align-items-center">
                      <!-- <label class="mr-3 mb-0 d-none d-md-block">Type:</label> -->
                      <select
                        @change="myFn"
                        v-model="placement_type"
                        class="form-control"
                        id="kt_datatable_search_type"
                      >
                        <option value="" selected disabled>Turi</option>
                        <option value="WH">Skladda</option>
                        <option value="EX">Xarajatga olingan</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-12 d-flex align-items-center">
                    <v-col cols="3" class="pl-0 py-0 col-md-5 my-0">
                    <v-autocomplete
                      v-model="storedproductservice__import_group"
                      :items="getCategoryGroupNames"
                      @input="myFnInit"
                      item-text="name"
                      dense
                      :success="
                        storedproductservice__import_group !== '' &&
                        storedproductservice__import_group !== null &&
                        storedproductservice__import_group !== undefined
                      "
                      item-value="id"
                      outlined
                      label="Guruh"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="3" class="pl-0 py-0 col-md-5 my-0 ">
                    <v-autocomplete
                      v-model="storedproductservice__import_category"
                      :items="getCategoryGroupChild"
                      @input="myFnInit"
                      item-text="name"
                      dense
                      :success="
                        storedproductservice__import_category !== '' &&
                        storedproductservice__import_category !== null &&
                        storedproductservice__import_category !== undefined
                      "
                      item-value="id"
                      outlined
                      label="Nomenklatura"
                    ></v-autocomplete>
                  </v-col>
                  <div class="col-lg-2 col-xl-4 text-right">
                <v-btn small @click="reset" color="error"
                  >{{ $t('BREADCRUMBS.CLEAN') }}
                </v-btn>
                  </div>
                  </div>
                </div>
              </div>
              <!-- <div class="col-lg-2 col-xl-4 mt-5 mt-lg-0 text-right">
                <v-btn small @click="reset" color="error"
                  >{{ $t('BREADCRUMBS.CLEAN') }}
                </v-btn>
              </div> -->
            </div>
          </div>
        </div>
        <div class="card card-custom gutter-b example example-compact">
          <div
            class="
              card-header
              d-flex
              align-items-center
              justify-content-between
            "
          >
            <div class="card-title">
              <h3 class="card-label">
                {{ $t('MENU.PURCHASE.RECEIPT_GOODS_SERVICES.BANK') }}
              </h3>
            </div>
            <div class="card-button">
              <v-btn small color="warning mx-2" @click="filter = !filter">
                {{ $t('BREADCRUMBS.SEARCH') }}
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
              <v-btn small to="/productscreate" class="ml-2" color="primary"
                >{{ $t('MENU.PURCHASE.RECEIPT_GOODS_SERVICES.BANK') }} +</v-btn
              >
            </div>
          </div>
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>
<style></style>
<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import debounce from 'lodash/debounce'
// import ContractsAction from "@/view/content/dropdown/ContractsAction.vue";
export default {
  data() {
    return {
      filter: false,
      placement_type: '',
      oper_date: '',
      from_date: '',
      to_date: '',
      reg_number__icontains: '',
      contragent__full_name__icontains: '',
      contragent_contract__reg_number__icontains: '',
      placement_expenditure__category__name__icontains: '',
      storedproductservice__total_withnds_summa: '',
      storedproductservice__nds_summa: '',
      storedproductservice__total: '',
      storedproductservice__import_group: '',
      storedproductservice__import_category: '',
      payTypes: [
        { name: 'Muddatli', value: 'MU' },
        { name: 'Muddati cheklanmagan', value: 'MS' },
        { name: "Summaga bog'liq", value: 'SU' }
      ],
      types: [
        { name: 'Kelishuv', value: 'NAQD' },
        { name: 'Shartnoma', value: 'CONT' }
      ]
    }
  },
  props: {
    items: Object
  },
  created() {
    this.$store.dispatch('getCategoryGroupNames')
    if (this.$store.state.requests.filterData.data !== undefined) {
      const data = this.$store.state.requests.filterData.data
      if (
        this.$store.state.requests.filterData.path.substring(
          0,
          this.$route.path.lastIndexOf('/')
        ) == this.$route.path.substring(0, this.$route.path.lastIndexOf('/'))
      ) {
        this.filter = true
        this.storedproductservice__total_withnds_summa =
          data.storedproductservice__total_withnds_summa
        this.storedproductservice__nds_summa =
          data.storedproductservice__nds_summa
        this.storedproductservice__total = data.storedproductservice__total
        this.placement_type = data.placement_type
        this.oper_date = data.oper_date
        (this.from_date = data.from_date)
          (this.to_date = data.to_date)
        this.reg_number__icontains = data.reg_number__icontains
        this.contragent__full_name__icontains =
          data.contragent__full_name__icontains
        this.contragent_contract__reg_number__icontains =
          data.contragent_contract__reg_number__icontains
        this.placement_expenditure__category__name__icontains =
          data.placement_expenditure__category__name__icontains
          this.storedproductservice__import_group =
          data.storedproductservice__import_group
          this.storedproductservice__import_category =
          data.storedproductservice__import_category
      } else {
        this.filter = false
        this.$store.commit('setFilterData', {
          data: undefined,
          path: ''
        })
      }
    }
  },
  methods: {
    myFnInit: debounce(function () {
      this.myFn()
    }, 400),
    operDate(d,key) {
      if (d !== null) {
        console.log(d);
        let currentDate = new Date(d)
        let y = currentDate.getFullYear()
        let m = (currentDate.getMonth() + 1).toString().length < 2 ? '0'+(currentDate.getMonth() + 1) : (currentDate.getMonth() + 1)
        let day = (currentDate.getDate()).toString().length < 2 ? '0'+(currentDate.getDate()) : (currentDate.getDate())
        if(key == 'from_date'){
          this.from_date = `${y}-${m}-${day}`
        }
        if(key == 'to_date'){
          this.to_date = `${y}-${m}-${day}`
        }
        this.myFn()
      } else {
        this.myFn()
        if(key == 'from_date'){
          this.from_date = ''
        }
        if(key == 'to_date'){
          this.to_date = ''
        }
      }
    },
    reset() {
      this.storedproductservice__total_withnds_summa = ''
      this.storedproductservice__nds_summa = ''
      this.storedproductservice__total = ''
      this.placement_type = ''
      this.oper_date = ''
      this.from_date = ''
      this.to_date = ''
      this.reg_number__icontains = ''
      this.contragent__full_name__icontains = ''
      this.contragent_contract__reg_number__icontains = ''
      this.placement_expenditure__category__name__icontains = ''
      this.storedproductservice__import_group = ''
      this.storedproductservice__import_category = ''
      this.$store.commit('setFilterData', {
        data: undefined,
        path: ''
      })
      this.myFn()
    },
    myFn() {
      const data = {}
      if (this.placement_type !== '') {
        data.placement_type = this.placement_type
      }
      if (this.oper_date !== '') {
        if (this.oper_date !== null) {
          data.oper_date = this.oper_date
        }
      }
      if (this.from_date !== '') {
        if (this.from_date !== null) data.from_date = this.from_date
      }
      if (this.to_date !== '') {
        if (this.to_date !== null) data.to_date = this.to_date
      }
      if (this.reg_number__icontains !== '') {
        data.reg_number__icontains = this.reg_number__icontains
      }
      if (this.contragent__full_name__icontains !== '') {
        data.contragent__full_name__icontains =
          this.contragent__full_name__icontains
      }
      if (this.contragent_contract__reg_number__icontains !== '') {
        data.contragent_contract__reg_number__icontains =
          this.contragent_contract__reg_number__icontains
      }
      if (this.placement_expenditure__category__name__icontains !== '') {
        data.placement_expenditure__category__name__icontains =
          this.placement_expenditure__category__name__icontains
      }
      if (this.storedproductservice__total_withnds_summa !== '') {
        data.storedproductservice__total_withnds_summa =
          this.storedproductservice__total_withnds_summa
      }
      if (this.storedproductservice__nds_summa !== '') {
        data.storedproductservice__nds_summa =
          this.storedproductservice__nds_summa
      }
      if (this.storedproductservice__total !== '') {
        data.storedproductservice__total = this.storedproductservice__total
      }
       if (this.storedproductservice__import_group !== '') {
        data.storedproductservice__import_group = this.storedproductservice__import_group
      }
      if (this.storedproductservice__import_category !== '') {
        data.storedproductservice__import_category = this.storedproductservice__import_category
      }
      this.$store.commit('setFilterData', {
        data: data,
        path: this.$route.path
      })
      if (Object.keys(data).length > 0) {
        data.operation_type = 'IMPORT'
        this.$store.dispatch('byBankSearch', { page: 1, payload: data })
      } else {
        this.$store.dispatch('getProductsList', 1)
      }
      if (this.$route.path !== '/products/1') {
        this.$router.push('/products/' + '1')
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('MENU.PURCHASE.TITLE') },
      { title: this.$t('MENU.PURCHASE.RECEIPT_GOODS_SERVICES.TITLE') },
      { title: this.$t('MENU.PURCHASE.RECEIPT_GOODS_SERVICES.BANK') }
    ])
  },
  computed: {
    getCategoryGroupNames() {
      const data = this.$store.state.requests.categoryGroupNames
      return data
    },
    getCategoryGroupChild() {
      return (this.$store.state.requests.categoryGroupNames.find(item => item.id === this.storedproductservice__import_group) || {}).categories || []
    }
  }
}
</script>
<style>
@media (min-width: 992px) {
  .content {
    padding: 0 !important;
  }
}
</style>
